import React, { Component } from "react";
import ApiService from "../services/ApiService";

class Info extends Component {
  constructor(props) {
    super();
    this.path = props.path;
    this.state = {
      id: props.param?.match.params.id,
      entity: {},
    };
  }

  componentDidMount() {
    (async () => this.setState({ entity: await this.getEntity() }))();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id) {
      this.setState({ entity: await this.getEntity() });
    }
    //this.forceUpdate
  }

  async getEntity() {
    const axios = ApiService.getAxios();
    return await axios
      .get(`${this.path}/id/${this.state.id.toString()}`)
      .then((res) => {
        return res.data;
      }); //.catch( error => this.props.history.push('/') )
  }

  renderItem(label, value) {
    return (
      <div className="revenue-item d-flex">
        <div className="revenue-desc">
          <h6>{label}</h6>
          <p className="font-weight-light"> {value} </p>
        </div>
        <div className="revenue-amount">
          <p className="text-primary"> {label} </p>
        </div>
      </div>
    );
  }

  render = () => {
    const getKeys = (obj) => {
      let keys = [];
      for (var key in obj) {
        keys.push(key);
      }
      return keys;
    };

    return (
      <div className="card">
        <div className="card-body">
          <ul className="list-group list-group-flush">
            {getKeys(this.state.entity).map((e, i) => {
              return (
                <li className="list-group-item">
                  {" "}
                  <strong> {e}: </strong> {Object.values(this.state.entity)[i]}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };
}

export default Info;
