import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
// import Alert from "./Alert"
import UserProfile from '../UserProfile';

class Navbar extends Component {

  constructor(){
    super();
    // this.removeAlert = this.removeAlert.bind(this);
    this.userProfile = UserProfile.getInstance();
    // this.state = { alerts: this.userProfile.getAlerts() };
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  render () {
    // let alertCount;

    // if (this.state.alerts.length > 0){
    //   let alertColour = "bg-info";
    //   if (this.state.alerts.filter((it) => this.filterFunction(it, 'success')).length > 0) alertColour = 'bg-success';
    //   if (this.state.alerts.filter((it) => this.filterFunction(it, 'warn')).length > 0) alertColour = 'bg-warning';
    //   if (this.state.alerts.filter((it) => this.filterFunction(it, 'error')).length > 0) alertColour = 'bg-danger';
    //   alertCount = <span className={'count ' + alertColour } >{this.state.alerts.length}</span>;
    // }

    return (
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt =>evt.preventDefault()}><img src={require("../../../assets/images/logo-mini.png")} alt="logo" /></a>
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <i className="mdi mdi-menu"></i>
          </button>
          <div className="navbar-nav navbar-nav-left header-links">
          </div>
          <div className="w-100 d-flex justify-content-center pl-5">
            <img src={require("../../../assets/images/logo_nssa.png")}  alt="logo"/>
          </div>
          <ul className="navbar-nav navbar-nav-right ml-lg-auto">
            {/* <li className="nav-item  nav-profile border-0 pl-4">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                  <i className="mdi mdi-bell-outline"></i>
                  { alertCount }
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown preview-list">
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" >
                      <div className="preview-item-content py-2" href="!#" onClick={(evn)=> { evn.preventDefault(); this.addAlert(); } }>
                          <h6 className="preview-subject font-weight-normal text-dark mb-1">Notificaciones</h6>
                      </div>
                      <div className="preview-item-content py-1 align-notification-right" >
                          <h6 className="preview-subject font-weight-normal text-dark mb-1 text-center d-flex align-items-right">
                            <i className="mdi mdi-notification-clear-all"  href="!#" onClick={(evn)=> { evn.preventDefault(); this.clearAlerts(); } } />
                          </h6>
                      </div>
                  </Dropdown.Item>
                  {this.state.alerts.map((alert, i) => {
                    return (<Alert key={i} alert={alert} removeAlert={ this.removeAlert } />);
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            <li className="nav-item  nav-profile border-0">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                  <span className="profile-text">{this.userProfile.getUserInfo().user}</span>
                  <i className="fa fa-user-circle"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={ () => { this.userProfile.getUserInfo().logout() } } >
                    <div className="d-flex">
                      <div className="d-flex align-items-center justify-content-center">
                        Salir <i className="mdi mdi-power"></i>
                      </div>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }

  // addAlert(){
  //   this.userProfile.addAlert("error", "Error Message Error Message Error Message ", "test error messageError Message Error Message Error Message ", "mdi-alert");
  //   this.setState({alerts: this.userProfile.getAlerts()});
  // }

  // removeAlert(key, alert){
  //   let alerts = this.userProfile.getAlerts();
  //   const index = alerts.indexOf(alert);
  //   if (index > -1) {
  //       alerts.splice(index, 1);
  //       this.userProfile.setAlerts(alerts);
  //       this.setState({alerts: alerts});
  //   }
  // }

  // clearAlerts(){
  //   this.userProfile.clearAlerts();
  //   this.setState({alerts: []});
  // }

  filterFunction(it, type){
    return it.type.toLowerCase() === type;
  }
}

export default Navbar;