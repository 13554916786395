import React, { Component } from 'react';
import {name as app_name, version as app_version}  from '../../../package.json';

class Footer extends Component {
  render () {
    return (
      <footer className={`footer${this.props.estilo === '-home' ? '-home' : ''}`}>
        <div className="container-fluid fixed-bottom">
          <div className="d-sm-flex justify-content-center justify-content-sm-between">
            <span className="float-sm-left d-block mt-sm-0 ml-5 pl-5">© 2021 Todos los derechos reservados | <a href="/terminos-y-condiciones/index.html">Términos y condiciones</a> | <a href="/terminos-y-condiciones/politicaprivacidad.html">Política de privacidad</a></span>
            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">{app_name} <i className="mdi mdi-application-cog text-danger"></i> V{app_version}</span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;