import Selector from './Selector'
import ApiService from '../../services/ApiService'
class SelectorLines extends Selector {
  
  async getOptions () {
    const axios = ApiService.getAxios()
    return await axios.get( this.path )
    .then(res => {
        if(res.status !== 200)
          return []
        else {
          let options = res.data.map(
            item => ( {value: item.lineMasterId, label: item.description } ) )
          return options
        }
    })
  }
}
export default SelectorLines;