import React, { Component, Fragment } from 'react'
import ApiService from '../../services/ApiService'
import BootstrapTable from 'react-bootstrap-table-next'
import overlayFactory from 'react-bootstrap-table2-overlay'
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator'

const PaginatedTable = ({ data }) => {

return <PaginationProvider pagination={paginationFactory(data.options)} >
{
  ({paginationProps, paginationTableProps }) => (
    <>
      <div>
          <BootstrapTable
              rowStyle = { data.hasDetailedView ? {cursor:'pointer'} : {} }  
              data={data.tableRows}
              columns={data.columns}
              keyField={data.columns[0].dataField}
              classes="table-hover"
              loading={data.loading}
              remote={true}
              sortable={data.columns}
              showColumns={true}
              manual // this would indicate that server side pagination has been enabled 
              { ...paginationTableProps }
              //Overlay de carga
              noDataIndication="La tabla está vacía"
              overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(18,139,252,0.8)'}) } }) }
            />
      </div>
      <div className="float-left mt-5">
        <PaginationTotalStandalone
            { ...paginationProps }
          />
      </div>
      <div className="float-right mt-5">
          <PaginationListStandalone { ...paginationProps} />
      </div>
    </>
)}

  </PaginationProvider>
}

class BasicTable extends Component {

  constructor(props) {
    super()
    this.history = props.history
    this.getTableData = this.getTableData.bind(this);
    this.changeFunction = this.changeFunction.bind(this);
    this.getTableOptions = this.getTableOptions.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.state = {
      hasDetailedView: props.hdv,
      tableRows: [],
      columns: props.columns.filter( it => it.isForTable ),
      path: props.path,
      loading: true,
      options: this.getTableOptions(1, 10, null, this.changeFunction),
      queryData: new Map(),
      checkedColumn : props.checkedColumn
    };
  }

  updateOrder(map){
    let m = new Map();

    for (const [key, value] of this.state.queryData) {
      m.set(key, value)
    }

    for (const [key, value] of map) {
      m.set(key, value)
    }

    this.setState({queryData:m})
  }

  changeFunction(page){
    this.getTableData(page, true)
  }

  compareMaps(map1, map2) {
    if (map1.size !== map2.size) {
        return false
    }
    for (var [key, val] of map1) {
        let testVal = map2.get(key)
        // in cases of an undefined value, make sure the key
        // actually exists on the object so there are no false positives
        if (testVal !== val || (testVal === undefined && !map2.has(key))) {
            return false
        }
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState){
    if (prevState !== this.state){
      if(!this.compareMaps( this.state.queryData, prevState.queryData )){
        (async () => {
          this.setState({tableRows: await this.getTableData(1, true)})
        })()
      }
    }
  }

  componentDidMount(){
    (async () => {
      this.setState({tableRows: await this.getTableData(1, false)})
    })()
  }

  buildRequest(page){
    let request = `${this.state.path}?page=${page-1}`
    
    for (const [key, value] of this.state.queryData) {
      request += `&${key}=${value}`
    }

    return request
  }

  async getTableData(page, setState) {
    if(setState) this.setState({loading: true})
    const axios = ApiService.getAxios()
    return await axios.get( this.buildRequest(page) ).then((response) => {
      const data = response.data.content != null ? response.data.content : []; 
      if(setState){
        this.setState({tableRows: [] })
        this.setState({tableRows: data })
        this.setState({options: this.getTableOptions(page, response.data.totalElements, this.changeFunction)})
        this.setState({loading: false})
      } else {
        this.state.tableRows = data
        this.state.options = this.getTableOptions(page, response.data.totalElements, this.changeFunction)
        this.state.loading = false
      }
      return data;
    })
  }

  getTableOptions(page, totalItems, onChangeFunc){
    const customTotal = (from, to, size) => {

      if(this.state.options.totalSize > 0){
        return(
          <span className="react-bootstrap-table-pagination-total">
            Mostrando { to - from + 1} resultados de un total de { size }
          </span>
        )
      }
      return (<> </>)
    }

    return {
      pageStartIndex: 1,
      page: page,
      sizePerPage: 10,
      totalSize: totalItems,
      onPageChange: onChangeFunc,
      alwaysShowAllBtns: true,
      custom: true,
      paginationTotalRenderer: customTotal
    }

  }

  render() {
    return (
      <Fragment>
        <div className="table-responsive">
          <PaginatedTable data={this.state}/>
        </div>
      </Fragment>
    )
  }

}

export default BasicTable;