import { Component } from 'react';

class Inspection extends Component {

  constructor(){
    super()
    this.path = process.env.REACT_APP_INSPECTION
  }
  
}

export default Inspection;
