import React, { Component } from 'react'

class BigCard extends Component{
    render (){
        return (
            <div className="row">
                <div className="col-lg-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BigCard;