import React, { Component } from 'react'
import Selector from '../../app/basic-ui/Selector'

class InputDropdownBasic extends Component {

  constructor(props){
    super(props)
    this.idx = props.idx;
    this.element = props.element
    this.handleChange = props.handleChange
  }

    render() {
        return <>
                <label  htmlFor={this.idx}>{this.element.detailedText != null ? this.element.detailedText : this.element.text}</label>
                <Selector 
                    id= {this.idx} 
                    path= {this.element.dropdownPath}
                    text= {this.element.text} 
                    property={this.element.dataField} 
                    placeholder = {`Seleccione ${this.element.text}`} 
                />
                {
                  this.element.hasError &&
                  <span className="text-danger">
                    {this.element.messageError}
                  </span>
                }
            </>
    }
}

export default InputDropdownBasic;