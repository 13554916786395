import React from 'react'
import Info from '../Info.jsx'
import BackButton from '../../app/basic-ui/BackButton'
import ApiService from '../../services/ApiService.js'
import ModifyButton from '../../app/basic-ui/ModifyButton'
import Inspector from '../entity/Inspector'
import Swal from 'sweetalert2';
import UserProfile from '../../app/shared/UserProfile'
import Error404 from '../../app/user-pages/Error404.js'
import Redirect from '../../app/utils/Redirect'

class InspectorView extends Info {
	constructor(props){
		super(props)
		this.id = props.param.match.params.id
		this.path = process.env.REACT_APP_INSPECTOR
    this.userProfile = UserProfile.getInstance()
    this.hasRenderPermission = this.userProfile.hasInspectorShowPermisson()
		this.hasViewPermission = this.userProfile.hasInspectorShowPermisson()
		this.whereToGoBack = '/verificadores'
		this.whereToGoBackChild = props.param.location.pathname
		
		this.state = {
			id : props.param?.match.params.id,
			entity : {},
      entityNotFound: false,
		}
	}
	
	async componentDidMount() {
		this.setState({ entity : await this.getEntity() })
	}
	
	async getEntity () {
		const AXIOS = ApiService.getAxios();
		return await AXIOS.get( `${this.path}/id/${this.state.id.toString()}` )
		.then( res => {
			if( res.status === 204 ){
				this.setState({ entityNotFound : true })
			}
			return res.data
		}).catch( ( err ) =>{
			this.setState({ errorResponse : true })
		})
}

	async getPermits () {
		const axios = ApiService.getAxios();
		return await axios.get( `${ this.path }/${ this.id }` )
		.then( ({ data }) => {
			let permits = Object.keys( data )
			.filter( key => key.includes( 'permit' ) )
			.map( key => ({ [key] : data[key].map( dk => ({ value : dk.id >= 0 ? dk.id : dk.lineMasterId, label : dk.description })) }) )
			return permits
		}
		)//.catch( error => this.props.history.push('/') )
	}
	
	changeStatus = () => (async () => {
		const AXIOS = ApiService.getAxios();
		return await AXIOS.post( window.globalConfig.apiUrl + process.env.REACT_APP_INSPECTOR_CHANGE_STATUS.replace( '{id}', this.id ) )
		.then(res => {
			Swal.fire({
				icon: 'success',
				title: `Cambio de estado correcto`,
				showConfirmButton: false,
				timer: 1500
			})
			this.setState({ entity: res.data });
		  	return res.data
		}).catch( ( err ) =>{
			Swal.fire({
				icon: 'error',
				title: `Ocurrio un error al cambiar el estado`,
				text: err.response.data.errors[0],
				showConfirmButton: false,
				timer: 1500
			})
		})
	})

	render = () => {
		const { id, genderDescription, dni, fullname, enabled, companyDescription } = this.state.entity
		const { entityNotFound } = this.state
		if ( entityNotFound )
      return <Redirect to="/user-pages/error-404"  />
		if( !this.hasRenderPermission )
      return <Error404/>
    else
			return (
					<div className="row">

						<div className="col-xl-12 col-lg-12 col-sm-12 grid-margin stretch-card">
							<div className="row flex-grow">

								<div className="col-xl-12 col-lg-6 col-sm-6 grid-margin-0 grid-margin-xl stretch-card">
									<div className="card card-revenue">
										<div className="card-body">
											<div className="d-flex w-100 h-100 justify-content-between align-items-center">
												<div className="mr-auto">
													<p className="highlight-text text-white"> <i className="fa fa-info-circle"></i> Identificador único </p>
												</div>
												<div className="ml-auto mt-2 mt-xl-0">
													<h1><strong><i className="fa fa-angle-double-right"></i> { id } </strong></h1>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-xl-12 col-lg-6 col-sm-6 stretch-card">
									<div className="card card-revenue-table mt-4 mt-sm-0 mt-xl-4">
										<div className="card-body">

											<div className="revenue-item d-flex">
												<div className="revenue-desc">
													<h6> G&eacute;nero (que figura en el DNI) </h6>
													<p className="font-weight-light"> { genderDescription } </p>
												</div>
												<div className="revenue-amount">
													<p className="text-primary"> G&eacute;nero </p>
												</div>
											</div>

											<div className="revenue-item d-flex">
												<div className="revenue-desc">
													<h6> DNI </h6>
													<p className="font-weight-light"> { dni } </p>
												</div>
												<div className="revenue-amount">
													<p className="text-primary"> DNI </p>
												</div>
											</div>

											<div className="revenue-item d-flex">
												<div className="revenue-desc">
													<h6>Nombre</h6>
													<p className="font-weight-light"> { fullname } </p>
												</div>
												<div className="revenue-amount">
													<p className="text-primary"> Nombre </p>
												</div>
											</div>

											<div className="revenue-item d-flex">
													<div className="revenue-desc">
														<h6> Habilitado </h6>
														<p className="font-weight-light"> { enabled  ? "Si" : "No"} </p>
													</div>
													<div className="revenue-amount">
														<p className="text-primary"> Habilitado </p>
													</div>
											</div>

											<div className="revenue-item d-flex">
													<div className="revenue-desc">
														<h6> Empresa </h6>
														<p className="font-weight-light"> { companyDescription } </p>
													</div>
													<div className="revenue-amount">
														<p className="text-primary"> Empresa </p>
													</div>
											</div>
										</div>
									</div>
								</div>
								<button
									className={ `btn btn-primary btn-lg ml-3 mt-4 float-left` }
									onClick={ this.changeStatus() }>
								<i className={ `fa fa-toggle-${ enabled  ? 'on' : 'off'} mr-2` }></i>
									{ enabled  ? 'Deshabilitar' : 'Habilitar'}
								</button>
								<ModifyButton entity={ new Inspector() } id={ this.id } whereToGoBack={ this.whereToGoBackChild }/>
								<BackButton whereToGoBack={ this.whereToGoBack }/>
							</div>
						</div>
					</div>
				)
	}
}
export default InspectorView;