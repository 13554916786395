import React, { Component } from "react";
import { Form } from "react-bootstrap";

class InputTextBasic extends Component {
  constructor(props) {
    super(props);
    this.idx = props.idx;
    this.element = props.element;
    this.handleChange = props.handleChange;
  }

  render() {
    return (
      <>
        <label htmlFor={this.idx}>
          {this.element.detailedText != null
            ? this.element.detailedText
            : this.element.text}
        </label>
        <Form.Control
          onChange={this.handleChange}
          onBlur={this.handleChange}
          className="form-control"
          name={this.element.dataField}
          id={this.idx === 0 ? "0" : this.idx}
          type="text"
          required={this.element.required}
          defaultValue={this.element.value}
        />
        {this.element.hasError && (
          <span className="text-danger">{this.element.messageError}</span>
        )}
      </>
    );
  }
}

export default InputTextBasic;
