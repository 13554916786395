import React, { Component,Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Spinner from '../app/shared/Spinner'

import Inspector from '../components/entity/Inspector.jsx'
import Inspection from '../components/entity/Inspection.jsx'
import ReportByDateAndDni from '../components/report/ReportByDateAndDni.jsx'
import InspectorCreate from '../components/create-views/InspectorCreate.jsx'
import InspectorModify from '../components/modify-views/InspectorModify'
import InspectorView from '../components/info-views/InspectorView.jsx'
import InspectionView from '../components/info-views/InspectionView'

import Error404 from './user-pages/Error404'
import UserProfile from './shared/UserProfile'

const MainPage = lazy(() => import('./user-pages/MainPage'))

class AppRoutes extends Component {
  constructor ( props ) {
    super()
    this.user = UserProfile.getInstance()
    this.hasGeneralPermisson = this.user.hasGeneralPermisson()
    this.hasInspectorCreatePermisson = this.user.hasInspectorCreatePermisson()
    this.hasInspectorShowPermisson = this.user.hasInspectorShowPermisson()
    this.hasInspectionShowPermisson = this.user.hasInspectionShowPermisson()
  }
  
  render () {
    if ( !this.hasGeneralPermisson ) return <Error404 />

    return (
      <Suspense fallback={ <Spinner/> }>
        <Switch>
          
          <Route exact path="/" component= { MainPage } />
          
          {/* Inspector - ROUTE */}
          <Route 
            path = { `/${new Inspector().friendlyPath}/crear` } 
            component = { ( param ) =>
              this.hasInspectorCreatePermisson
              ? <InspectorCreate 
                 param = { param }
                 entity = { new Inspector() }
                />
              : <Error404 />
            }
          />
          <Route 
            path = { `/${new Inspector().friendlyPath}/informacion-detallada/:id` }
            component = { ( param ) => 
              this.hasInspectorShowPermisson
              ? <InspectorView 
                 param = { param }
                 path = { new Inspector().path }
                />
              : <Error404 />
            }
          />
          <Route 
            path = { `/${new Inspector().friendlyPath}/modificar/:id` }
            component = { ( param ) => 
              this.hasInspectorCreatePermisson
              ? <InspectorModify 
                 param = { param }
                 entity = { new Inspector() }
                />
              : <Error404 />
            }
          />
          {/* Inspector - ROUTE END */}

          {/* ReportByDateAndDni - ROUTE START */}
          <Route 
            path = { `/${new ReportByDateAndDni().friendlyPath}/informacion-detallada/:id` }
            component = { ( param ) => 
              this.hasInspectionShowPermisson
              ? <InspectionView
                  param = { param }
                  path = { new Inspection().path }
                />
              : <Error404 />
            }
          />
          {/* ReportByDateAndDni - ROUTE END */}

          <Route exact path={ `/${new Inspector().friendlyPath}` } component= { ({ history }) =>  <Inspector history={ history }/> } />
          <Route exact path={ `/${new ReportByDateAndDni().friendlyPath}` } component= { ({ history }) =>  <ReportByDateAndDni history={ history }/> } />
          
          <Redirect to="/user-pages/error-404" />
        </Switch>
      </Suspense>
    )
  }
}
export default AppRoutes