import React, { Component, Fragment } from 'react'
import BackButton from '../app/basic-ui/BackButton'
import ApiService from '../services/ApiService'
import Input from '../app/utils/Input.jsx'
import Swal from 'sweetalert2'
import Redirect from '../app/utils/Redirect'

class Create extends Component {

  constructor(props) {
    super()
    this.id = props.param.match.params.id
    this.path = props.entity.path
    this.history = props.param.history
    this.state = {
      isInputDisabled: false,
      entityNotFound: false,
      errorResponse: undefined,
      okResponse: false,
      entity: props.entity.columns.map( e => {
        if( e.inputType === 'checkbox' ) e.value = e.defaultValue
        return e
      }).filter( e => e.isForCreate )
    }
  }

  async componentDidMount(){
    if( this.id !== undefined ){
      let aux = this.state.entity
      let res = await this.getEntity()
      this.getKeys( res ).forEach( ( e, i ) => {
        aux.forEach( entity => {
          if( e === entity.dataField ){
            entity.value = Object.values( res )[i]
          }
        } )
      })

      this.setState( { entity: aux } )
    }
  }

  getKeys = obj => {
    let keys = []
    for(let key in obj){
       keys.push( key )
    }
    return keys
  }

  async getEntity () {
    const AXIOS = ApiService.getAxios()
      return await AXIOS.get( `${ this.path }/${ this.id.toString().replace( /,/g , '-' )}` )
      .then( res => {
        if( res.status === 204 ){
          this.setState({ entityNotFound : true })
        }
        return res.data
      }).catch( ( err ) =>{
        this.setState({ errorResponse : true })
      })
  }

  checkError = ( value, index ) => {
    this.setState({ errorResponse : undefined })
    let aux = this.state.entity
    this.setState({ entity:aux })
  }

  async sendDataToAPI ( formValues, successName )  {

    const AXIOS = ApiService.getAxios()
    const PATH = `${ this.path }`
    const REQUEST = this.id ? formValues : this.buildRequest( formValues )

    if( this.id === undefined ){
      return await AXIOS.post( PATH, REQUEST )
        .then( response => this.dataProcess( true, response, successName ) )
        .catch( error => { this.errorDataProcess( error.response ) })
        .finally( () => this.setState({ isInputDisabled : false }) )
    } else {
        REQUEST.id = this.id

        return await AXIOS.patch( PATH, REQUEST )
          .then( response => this.dataProcess ( false, response, successName ) )
          .catch( error => {
            this.errorDataProcess( error.response )
            if( error.response )
              this.setState({ errorResponse : error.response.data.errors })
          })
          .finally( () => this.setState({ isInputDisabled : false }) )
          }
    }

  dataProcess = ( wasSaved, response, successName ) => {
    this.setState({ okResponse : response.status === 200 || response.status === 201 })
    Swal.fire({
      icon: 'success',
      title: `${ this.capitalizeFirstChar( successName ) } ${ wasSaved ? 'guardado/da' : 'modificado/da' }`,
      showConfirmButton: false,
      timer: 1500
    })
    let location = this.history.location.pathname
    if( location.includes( 'modificar' ) ){
      location = location.replaceAll( 'modificar' , 'informacion-detallada' )
    }else{
      location = location.replaceAll( 'crear' , 'informacion-detallada' )
      location += `/${ response.data.id }`
    }
    setTimeout( () => this.history.push( location ), 2000 )
  }

  errorDataProcess = response => {
    this.setState({ okResponse : response.status === 200 || response.status === 201 })
    Swal.fire({
      icon: 'error',
      title: `Ocurrio un Error`,
      text: response.data.errors ? response.data.errors[0] : `Error ${ response.status }.`,
      showConfirmButton: false,
      timer: 2500
    })
  }

  buildRequest = formValues => {
    
        let object = {}
        for( let[key,value] of formValues ) { object[key] = value }
        delete object['']
        return object
  }

  handleChange = e => this.checkError( e.target.value, e.target.id )

  getCategoryName = () => {
    const PATHINCLUDES = string => this.history.location.pathname.includes( string )
    if ( PATHINCLUDES( 'SFTP' ) ) return 'SFTP'
    if ( PATHINCLUDES( 'reportes' ) ) return 'reportes'
    if ( PATHINCLUDES( 'cryptoData' ) ) return 'cryptoData'
  }

  getCrudName(){
    //Si es solo categoría se devolverá ese nombre
    const categoryName = this.getCategoryName()
    if( categoryName ) return categoryName 

    let name = this.history.location.pathname.replace(/-/g , ' ')
    name = name.slice( name.indexOf('/') + 1 )
    name = name.slice( name.indexOf('/') + 1 )

    if( name.indexOf('/') !== -1 )
      name = name.slice( 0, name.indexOf('/') )

    //Si es una palabra en plural se elimina la S para obtener el singular
    if( name.charAt( name.length - 1 ) === 's' ){
      name = name.slice( 0, -1 )
      //Si era una palabra con plural que contenía un E (por ejemplo entidades) también se eliminará
      if( name.charAt( name.length - 1 ) === 'e' )
        name = name.slice( 0, -1 )
    }

    return name
  }

  capitalizeFirstChar = s => s.charAt( 0 ).toUpperCase() + s.slice( 1 )

  render(){
    const form = () => 
      <div className='container'>
          <div className='card'>
            <div className='card-body'>
              { this.state.isInputDisabled && 
                  <h1 className='text-center'><i className='fa fa-spinner fa-spin'></i> Guardando... </h1>
              }
              <BackButton isCreate={ true }/>
              <h4 className='card-title'> Alta { this.getCrudName() }</h4>
              <form onSubmit={ this.handleSubmit } className='form-sample'>
                {
                  this.state.entity.map(
                    ( e, i ) => (
                      e.isForCreate && //Si no es para creación, no se dibuja el campo.
                      <Input element={ e } idx={ i } isDisabled={ this.state.isInputDisabled }/>
                    )
                  )
                }
                { this.props.children }

                <div className='row'>

                  <button type='reset' className='btn btn-warning btn-lg col-md m-2'> 
                    <i className='fa fa-undo mr-2'></i>
                    Restablecer
                  </button>

                  <button type='submit' className='btn btn-primary btn-lg col-md m-2'> 
                    <i className='fa fa-save mr-2'></i>
                      {`${ this.id === undefined ? 'Crear' : 'Guardar modificación' }` }
                  </button>

                </div>
              </form>
            </div>

          </div>
          
        </div>
    
    return(
      <Fragment>
        { this.state.entityNotFound && <Redirect history={ this.history }/> }
        { !this.state.entityNotFound && form() }
      </Fragment>
    )
  }
}
export default Create