import React, { Component } from 'react'
import { Form } from 'react-bootstrap'

class Input extends Component {

  constructor(props) {

    super()

    this.state = {
      isDisabled : props.isDisabled
    }

    //Props base (las usa el filtro)
    this.label = props.label
    this.type = props.type

    //Props de Tula
    this.element = props.element
    this.idx = props.idx
    this.handleChange = props.handleChange

  }

  isUndefined = prop => prop === undefined

  render() {

    if (
      !this.element &&
      !this.idx &&
      !this.handleChange
    ){
      return (
        <div className="col-lg">
          <label htmlFor={this.props.name}>
            {this.label}
          </label>
          <Form.Control
            name={this.props.name}
            type={this.props.type}
            id={this.props.name}
            max={this.props.max}
            required={this.props.required}
            min={0}
            disabled={this.state.isDisabled}
          />
        </div>
      )
    }else{

      return(

        <Form.Group>
          <label htmlFor={this.idx}>{this.element.text}</label>
          <Form.Control
            placeholder={this.element.placeholder}
            onChange={this.handleChange}
            onBlur={this.handleChange}
            name={this.element.dataField}
            id={this.idx === 0 ? '0' : this.idx}
            type={this.element.type}
            min={this.element.type === 'number'? 0 : undefined}
            defaultValue={this.element.value}
            disabled={this.state.isDisabled}
          />
          {
            this.element.hasError &&
            <span className="text-danger">
              {this.element.messageError}
            </span>
          }
        </Form.Group>
      )
    
    }

  }

}

export default Input;
