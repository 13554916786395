import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/navbar/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {

  state = { 
    mainPage: true
  }

  componentDidMount = () => {
    //ApiService.get(process.env.REACT_APP_PATH_HEALTH, data => { return data.data } )
    this.onRouteChanged()
    document.title = this.generateTitle()
  }

  render () {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer estilo={this.props.location.pathname === '/' ? '-home' : ''}/> : '';//TODO cambiar atributo a "style"
    return (
      <div className="container-scroller">
        { navbarComponent }
        <div className="container-fluid page-body-wrapper">
          { sidebarComponent }
          <div className="main-panel">
            {/* <div className='content-wrapper'> */}
            <div className={`content-wrapper${this.props.location.pathname === '/' ? '-home' : ''}`}>
              <AppRoutes/>
            </div>
            { footerComponent }
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      document.title = this.generateTitle()
      this.onRouteChanged()
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/user-pages/login-1', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

  generateTitle = () => {
    return this.getCategoryName() + this.getPageName() + this.getActionName()
  }

  getCategoryName(){

    //Repetí este condicional por cada categoría que tengas con el siguiente formato
    if(this.includes('/verificadores'))
      return 'TMI | Verificadores'

    if(this.includes('/inspecciones'))
      return 'TMI | Inspecciones'
      
    return 'TMI'

  }

  includes = ( string ) => this.props.history.location.pathname.includes(string)

  getPageName(){
    if( this.props.history.location.pathname === '/')
      return ' | Inicio'
    if( this.includes('verificadores') || this.includes('inspecciones') )
      return ''
    let name = this.props.history.location.pathname.replace(/-/g , ' ')
    name = name.slice( name.indexOf('/') + 1 )
    name = name.slice( name.indexOf('/') + 1 )
    name = name[0].toUpperCase() + name.slice(1)
    if( name.indexOf('/') !== -1 )
      name = name.slice( 0, name.indexOf('/') )
    return ` | ${name}`
  }

  getActionName(){

    if( this.includes('informacion') )
      return ' | Información detallada'

    if( this.includes('crear') )
      return ' | Creando '

    if( this.includes('modificar') )
      return ' | Modificando'
    
    return ''

  }

}

export default withRouter(App);
