import React, { Component } from 'react';
import Table from '../../app/tables/Table.jsx';
import UserProfile from '../../app/shared/UserProfile'
import Error404 from '../../app/user-pages/Error404'

class Inspector extends Component {
  constructor(){
    super()
    this.title = 'Verificadores'
    this.friendlyPath = 'verificadores'
    this.path = process.env.REACT_APP_INSPECTOR
    this.userProfile = UserProfile.getInstance()
    this.hasRenderPermission = this.userProfile.hasInspectorShowPermisson()

    this.columns = [
      {
        dataField: 'id',
        text: 'Id Validador',
        isForTable: true,
        inputType: 'text',
        errorMessage: "El campo requiere al menos un caracter",
        isForCreate: false,
        isForModify: false,
        required: true,
      },
      {
        dataField: 'dni',
        text: 'DNI',
        isForTable: true,
        requestName : 'dni',
        inputType: 'number',
        errorMessage: 'Ingrese un DNI válido.',
        hasError: true,
        isBlank: true,
        isFilter: true,
        isForCreate: true,
        isForModify: false,
        min: 1,
        max: 99999999,
        value: undefined
      },
      {
        dataField: 'fullname',
        text: 'Nombre',
        isForTable: true,
        inputType: 'text',
        errorMessage: "Ingrese un nombre valido",
        isForCreate: false,
        isForModify: false,
      },
      {
        dataField: 'gender',
        text: 'Género',
        isForTable: true,
        requestName : 'gender',
        inputType: 'dropdown',
        detailedText: 'Género (como figura en el DNI)',
        errorMessage: "Seleccione el género como figura en su DNI.",
        ref: React.createRef(),
        hasError: true,
        isBlank: true,
        isFilter: false,
        isForCreate: true,
        isForModify: false,
        dropdownPath: process.env.REACT_APP_GENDERS,
        formatter: function(cell,row,rowIndex,formatExtraData) {
          return row.genderDescription
        }
      },
      {
        dataField: 'enabled',
        text: 'Habilitado',
        isForTable: true,
        requestName : 'enabled',
        inputType: 'checkbox',
        errorMessage: "Tilde según corresponda.",
        isForCreate: true,
        isForModify: false,
        defaultValue: true,
      },
      {
        dataField: 'lineId',
        text: 'Linea',
        requestName : 'permitAtLines',
        inputType: 'dropdown',
        errorMessage: "Seleccione al menos una linea.",
        ref: React.createRef(),
        isFilter: false,
        isMulti: true,
        isForCreate: true,
        isForModify: true,
        dropdownPath: process.env.REACT_APP_LINES,
        value: [],
        formatter: function(cell,row,rowIndex,formatExtraData) {
          return row.lineDescription
        }
      },
      {
        dataField: 'companyId',
        text: 'Empresa',
        isForTable: true,
        requestName : 'permitAtCompanies',
        inputType: 'dropdown',
        errorMessage: "Seleccione al menos una empresa.",
        ref: React.createRef(),
        isFilter: false,
        isMulti: true,
        isForCreate: true,
        isForModify: true,
        dropdownPath: process.env.REACT_APP_COMPANIES,
        value: [],
        formatter: function(cell,row,rowIndex,formatExtraData) {
          return row.companyDescription
        }
      },
      {
        dataField: 'provinceId',
        text: 'Provincia',
        requestName : 'permitAtProvinces',
        inputType: 'dropdown',
        errorMessage: "Seleccione al menos una provincia.",
        ref: React.createRef(),
        isFilter: false,
        isMulti: true,
        isForCreate: true,
        isForModify: true,
        dropdownPath: process.env.REACT_APP_PROVINCES,
        value: [],
        formatter: function(cell,row,rowIndex,formatExtraData) {
          return row.lineDescription
        }
      },
      {
        dataField: 'municipalityId',
        text: 'Municipalidad',
        requestName : 'permitAtMunicipalities',
        inputType: 'dropdown',
        errorMessage: "Seleccione al menos una municipalidad.",
        ref: React.createRef(),
        isFilter: false,
        isMulti: true,
        isForCreate: true,
        isForModify: true,
        dropdownPath: process.env.REACT_APP_MUNICIPALITIES,
        value: [],
        formatter: function(cell,row,rowIndex,formatExtraData) {
          return row.lineDescription
        }
      },
      {
        dataField: 'jurisdictionId',
        text: 'Jurisdicción',
        requestName : 'permitAtJurisdictions',
        inputType: 'dropdown',
        errorMessage: "Seleccione al menos una jurisdicción.",
        ref: React.createRef(),
        isFilter: false,
        isMulti: true,
        isForCreate: true,
        isForModify: true,
        dropdownPath: process.env.REACT_APP_JURISDICTIONS,
        value: [],
        formatter: function(cell,row,rowIndex,formatExtraData) {
          return row.lineDescription
        }
      }
    ]
  }

  render = () => {
		if(!this.hasRenderPermission)
      return <Error404/>
    else
      return(
        <Table 
          columns={this.columns} 
          path={this.path}
          friendlyPath={this.friendlyPath}
          hasCreate={true}
          history={this.props.history}
        />
      )
  }
      
}

export default Inspector;