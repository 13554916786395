import React from 'react'
import BackButton from '../../app/basic-ui/BackButton'
import Create from '../Create'
import InputNumberBasicFC from '../inputs/InputNumberBasicFC '
import InputCheckboxBasic from '../inputs/InputCheckboxBasic'
import Selector from '../../app/basic-ui/Selector'
import SelectorLines from '../../app/basic-ui/SelectorLines'
import { Form } from 'react-bootstrap'
import UserProfile from '../../app/shared/UserProfile'
import Error404 from '../../app/user-pages/Error404'
import ApiService from '../../services/ApiService'
import Spinner from '../../app/shared/Spinner'
import Redirect from '../../app/utils/Redirect'

class InspectorModify extends Create {
  constructor( props ) {
    super( props )
    this.id = props.param.match.params.id
    this.entity = props.entity
    this.resetForm = this.resetForm.bind( this )
    this.getInputValue = this.getInputValue.bind( this )
    this.userProfile = UserProfile.getInstance()
    this.hasRenderPermission = this.userProfile.hasInspectorCreatePermisson()
    this.whereToGoBack = props.param.location?.whereToGoBack

    this.state= {
      entity: props.entity.columns.filter( e => e.isForModify ),
      errorCounter: 1,
      permitCounter: 1,
      blankForm: true,
      dropDownValues: [],
      loading: true,
      entityNotFound: false,
    }
  }

  componentDidMount = async () => {
      let aux = this.state.entity
      let res = await this.getEntity()
      this.getKeys( res ).forEach( ( e, i ) => {
        aux.forEach( entity => {
          if( e === entity.dataField ){
            entity.value = Object.values( res )[i]
          }
        } )
      })
      this.setState( { entity: aux } )
      let permits = await this.getPermits()
  		this.setState({ dropDownValues : permits })
      let counter = 0
      aux.map( e => {
        permits.forEach( p => {
          if ( e.requestName === Object.keys( p )[0]) {
            e.value = Object.values( p )[0]
            counter += e.value.length
          }
        })
        return e
      })
      this.setState({ permitCounter : ( 1 - counter ) })
      this.setState({ errorCounter : ( 1 - counter ) })
      this.setState({ loading : false } )
  }

  getPermits = async () => {
		const axios = ApiService.getAxios()
		return await axios.get( `${ this.path }/${ this.id }` )
		.then( ({ data }) => {
			let permits = Object.keys( data )
			.filter( key => key.includes( 'permit' ) )
			.map( key => ({
              [key] : data[key]
              .map( dk => {
                return { value : dk.id >= 0 ? dk.id : dk.lineMasterId, label : dk.description }
              })
            })
      )
			return permits
		}
		)//.catch( error => this.props.history.push('/') )
	}
  permitCounter = () => {
    const { dropDownValues } = this.state

    dropDownValues.map ()
  }

  handleSubmit = ( e ) => {
    e.preventDefault()
    this.setState({ blankForm : false })

    let modifyValues = {}
    let formHasErrors = false
    const { entity, errorCounter } = this.state

    entity.forEach( e => {
      if( e.hasError ){
        e.isBlank= false
        formHasErrors= true
      }
    })
    console.warn(('formHasErrors', formHasErrors))
    
    if( !formHasErrors && errorCounter < 1 ) {
        modifyValues = this.takeDropDownsValues()

        for(let key in modifyValues)
          modifyValues[key] = modifyValues[key].map( e => ({ entityId : e.value }) )  // map inner arrays into the right format( key = entityId )
      
        this.sendDataToAPI( modifyValues, 'Inspector' )
    }
  }

  takeDropDownsValues = () => {
    let obj = {}
    this.state.entity.forEach( e => { obj[e.requestName] = e.ref.current.state.value ? e.ref.current.state.value : [] })
    return obj
  }

  newFormValidation = async delta => {
    this.setState({ blankForm : false })
    let errorCounter = this.state.errorCounter + delta
    await this.setState({ errorCounter })
  }
  
  getInputValue = ( e, name ) => {
    let { entity } = this.state
    let obj = {}
    let entityClone = {}
    let properEntity = this.state.entity.filter( e => e.dataField === name)[0]
    let prevCant = properEntity.value?.length
    
    if( e?.length === 0 || e === null || e.target?.value === null ){// If event is null result of cleaning dropdown single or multi
      obj.value = []
      name !== 'gender'
      ? this.newFormValidation( prevCant )// Occurs when delete a selected value from any dropdown
      : obj.hasError = true
      
    } else {
      if( e.target?.value ){// Occurs when type a value into a <div>(DNI)
        obj.value = e.target.value
        obj.value.length < 7 ? obj.hasError = true : obj.hasError = false
        obj.isBlank = false
        
      } else if( e.value ){// Occurs when select value from gender
        obj.value = e.value
        obj.hasError = false
        
      } else if( name !== 'dni' ){
        let newCant = e.length
        let delta = prevCant - newCant
        this.newFormValidation( delta )// Occurs when select value from any dropdown
        obj.value = e
      }
    }

    entityClone = entity.map( ent => {
      if( ent.dataField === name )
        ent = { ...ent, ...obj }
      return ent
    })

    this.setState({ entity : entityClone })
  }

  resetForm = e => {
    let aux = this.state.entity
    this.setState({ blankForm : true })
    aux.forEach( ( e ) => {
      if( e.inputType === 'dropdown' ) {
        e.ref.current.select.clearValue()
        e.value = []
      }

      if( e.dataField === 'dni' || e.dataField === 'gender' ) {
        e.hasError = true
        e.isBlank = true
        e.value = ''
      }
    })
    this.setState({ entity : aux })
    this.setState({ errorCounter : 1 })//makes resetForm function reset permits as they were originally
    this.setState({ blankForm : true })
    this.forceUpdate()
  }

  createInput = ( e, i ) => {
    const { dropDownValues } = this.state
    switch( e.inputType ) {
      case 'number':
        return  <Form.Group key={ i }>
                  <InputNumberBasicFC 
                    idx={ i } 
                    element={ e }
                    property={ e.dataField }
                    type={ e.type }  
                    getValueForCreate={ this.getInputValue } 
                  />
                {
                  ( ( !e.isBlank && e.value?.length < 7 ) ||
                    ( !e.isBlank && e.hasError ) ) &&
                  <span className="text-danger">
                    { e.errorMessage }
                  </span>
                }
                </Form.Group>
      case 'checkbox':
        return  <Form.Group key={ i }>
                  <InputCheckboxBasic 
                    idx={ i } 
                    element={ e } 
                    handleChange={ null } />
                </Form.Group>
      case 'dropdown':
        let props = {
          id: i,
          path: e.dropdownPath,
          text: e.text,
          property: e.dataField,
          isForCreate: e.isForCreate,
          isMulti: e.isMulti,
          placeholder: `Seleccione ${ e.text }`,
          refer: e.ref,
          defaultValue: dropDownValues.length > 0 ? dropDownValues.filter( dv => ( Object.keys( dv )[0] === e.requestName ) )[0][e.requestName] : [],
          getValueForCreate: this.getInputValue
        }
        return  <Form.Group key={ i }>
                  <label  htmlFor={ i }>{ e.detailedText ? e.detailedText : e.text }</label>
                  {
                    e.dataField === 'lineId'
                      ? <SelectorLines { ...props }/>
                      : <Selector { ...props }/>
                  }
                  {
                    ( e.dataField === 'gender' &&
                    (( e.hasError && !e.isBlank ) || ( e.isBlank && e.value === [] )) ) &&
                    <span className="text-danger">
                      { e.errorMessage }
                    </span>
                  }
                </Form.Group>
      default:
        return 'No Def'
    }
  }
  
  render(){
    const { errorCounter, blankForm, entityNotFound } = this.state
    if (entityNotFound)
      return <Redirect to="/user-pages/error-404"  />
    
    if( !this.hasRenderPermission )
      return <Error404/>
    else  if( this.state.loading )
            return <Spinner/>
          else
            return(
                <div className="container">
                  <div className="card">
                    <div className="card-body">  
                      <div className="row">

                        <div className='col d-flex align-items-center padding-top-2'>
                          <h4 className="card-title d-flex align-items-center"> { !this.id ? 'Alta ' : 'Modificación ' } { this.entity.title } </h4>
                        </div>
                        <div className='col '>
                          <BackButton whereToGoBack={ this.whereToGoBack ? this.whereToGoBack : '/verificadores' }/>
                        </div>

                      </div>  
                      <form onSubmit={ this.handleSubmit } className="form-sample">

                { this.state.entity.map( ( e, i ) => this.createInput( e, i ) ) }

                  <div className="row">
                  { 
                    ( errorCounter > 0 && !blankForm ) &&
                    <span className="text-danger">
                      Debe seleccionar al menos un permiso.
                    </span>
                  }
                  </div>
                <div className="row">

                        <button type="submit" className="btn btn-primary btn-lg col-md m-2"> 
                          <i className="fa fa-save mr-2"></i>
                            { `${ this.id === undefined ? 'Crear' : 'Guardar modificación' }` }
                        </button>

                        <button type="reset" onClick={ this.resetForm } className="btn btn-warning btn-lg col-md m-2"> 
                          <i className="fa fa-undo mr-2"></i>
                          Restablecer
                        </button>
                        
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
            )
  }
}
export default InspectorModify