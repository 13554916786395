import React, { Component } from "react";
import Selector from "../../app/basic-ui/Selector";
import InputNumberBasic from "../../components/inputs/InputNumberBasic.js";
import InputTextBasic from "../../components/inputs/InputTextBasic.js";
import InputCheckboxBasic from "../../components/inputs/InputCheckboxBasic.js";
import InputDropdownBasic from "../../components/inputs/InputDropdownBasic.js";
import InputDateBasic from "../../components/inputs/InputDateBasic";
import { Link } from "react-router-dom";
import ApiService from "../../services/ApiService";
import { saveAs } from "file-saver";
import UserProfile from "../shared/UserProfile";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.userProfile = UserProfile.getInstance();
    this.errorMessage = props.errorMessage;
    this.filters = props.filters;
    this.request = new Map();
    this.hasDownload = props.hasDownload;
    this.exportPath = props.exportPath;
    this.onSend = props.onSend;

    this.state = {
      showMessage: false,
      exportPushed: false,
      exportPathValues: new Map(),
      filterValues: this.filters.reduce((acc, filter) => {
        acc[filter.dataField] = filter.inputType === "checkbox" ? false : "";
        return acc;
      }, {}),
    };
  }

  handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    this.setState((prevState) => ({
      filterValues: {
        ...prevState.filterValues,
        [name]: newValue,
      },
    }));

    this.putValueOnRequestMap(name, newValue);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { exportPushed } = this.state;

    let values = this.pickUpValues(e);
    let oneInputFilled = values.some((value) => value);

    if (!oneInputFilled) {
      Array.from(e.target).forEach((input) => (input.required = "required"));
    }

    if (e.target.reportValidity()) {
      const query = this.generateRequestPath(this.request);
      console.log("URL:", query);

      Array.from(e.target).forEach((input) => {
        const value = input.type === "checkbox" ? input.checked : input.value;
        this.request.set(input.name, value);
      });

      this.request.delete(undefined);

      this.setState({ exportPathValues: this.request });

      if (exportPushed) {
        this.setState({ exportPushed: false });
        ApiService.getAxios()
          .get(query)
          .then((response) => {
            const blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            saveAs(blob, this.generateFileName());
          });
      } else {
        this.onSend(this.request);
        this.request.clear();
      }
      this.resetFilterValues();
    } else {
      this.setState({ showMessage: true });
      setTimeout(() => this.removeRequired(oneInputFilled, e.target), 5000);
    }
  };

  resetFilterValues = () => {
    this.setState({
      filterValues: this.filters.reduce((acc, filter) => {
        acc[filter.dataField] = filter.inputType === "checkbox" ? false : "";
        return acc;
      }, {}),
    });
  };

  generateFileName = () => {
    const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
    return (
      "Inspecciones " + new Date().toLocaleDateString("es-AR", options) + ".csv"
    );
  };

  generateRequestPath = (request) => {
    let query = this.exportPath + "?";
    for (let [key, value] of request) {
      query += `${key}=${value}&`;
    }
    return query.slice(0, -1);
  };

  pickUpValues = (e) => {
    this.setState({
      showMessage: false,
      hasBeenSend: true,
    });

    return Array.from(e.target).map((INPUT) => {
      if (INPUT.type === "checkbox") return INPUT.checked;
      if (INPUT.type === "date" && INPUT.value) {
        Array.from(e.target).forEach((aux) => {
          if (aux.type === "date") aux.required = "required";
        });
      }
      return INPUT.value;
    });
  };

  removeRequired = (oneInputFilled, target) => {
    if (!oneInputFilled && target) {
      Array.from(target).forEach((input) => {
        if (input) input.required = "";
      });
    }
  };

  putValueOnRequestMap = (name, value) => {
    this.request.set(name, value || null);
  };

  drawInput = (filter, idx) => {
    if (filter.path && filter.label) {
      return (
        <div className="col-lg" key={idx}>
          <label htmlFor={this.props.name}>{filter.label}</label>
          <Selector
            id={idx}
            path={filter.path}
            text={filter.label}
            placeholder={`Seleccione ${filter.label}`}
            property={filter.dataField}
            isForFilter={true}
            getValueForFilter={this.putValueOnRequestMap}
            value={this.state.filters[filter.dataField] || ""}
          ></Selector>
        </div>
      );
    } else {
      return (
        <div className="col-lg" key={idx}>
          <label htmlFor={this.props.name}>{filter.label}</label>
          {this.createInput(filter, idx)}
        </div>
      );
    }
  };

  createInput = (element, idx) => {
    const { filterValues } = this.state;

    switch (element.inputType) {
      case "number":
        return (
          <InputNumberBasic
            idx={idx}
            element={element}
            handleChange={this.handleChange}
            value={filterValues[element.dataField] || ""}
          />
        );
      case "text":
        return (
          <InputTextBasic
            idx={idx}
            element={element}
            handleChange={this.handleChange}
            value={filterValues[element.dataField] || ""}
          />
        );
      case "checkbox":
        return (
          <InputCheckboxBasic
            idx={idx}
            element={element}
            handleChange={this.handleChange}
            checked={filterValues[element.dataField] || false}
          />
        );
      case "dropdown":
        return (
          <InputDropdownBasic
            idx={idx}
            element={element}
            handleChange={this.handleChange}
            value={filterValues[element.dataField] || ""}
          />
        );
      case "date":
        return (
          <InputDateBasic
            idx={idx}
            element={element}
            handleChange={this.handleChange}
            value={filterValues[element.dataField] || ""}
          />
        );
      default:
        return "No se encontró el filtro adecuado.";
    }
  };

  drawErrorMessage = () => this.errorMessage || "Debe completar los campos";

  render() {
    const buttonStyle = {
      height: "2.8rem",
      marginTop: "2rem",
    };

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="card">
          {this.state.showMessage && (
            <div className="alert alert-danger" role="alert">
              {this.drawErrorMessage()}
            </div>
          )}
          <div className="card-body row">
            {this.filters.map((filter, idx) => this.drawInput(filter, idx))}

            <button
              type="submit"
              className="col-lg-auto btn btn-outline-primary ml-3 mr-3"
              style={buttonStyle}
              // onClick={this.resetFilterValues}
            >
              <i className="mdi mdi-magnify mr-1"></i>
              Buscar
            </button>

            {this.hasDownload &&
              this.userProfile.hasInspectorReportPermisson() && (
                <div className="mb-5 pb-4">
                  <button
                    type="submit"
                    className="col-lg-auto btn btn-outline-primary ml-3 mr-3"
                    style={buttonStyle}
                    onClick={() => this.setState({ exportPushed: true })}
                  >
                    <i className="fa fa-download"></i>
                    Exportar
                  </button>
                </div>
              )}

            {!this.errorMessage && this.state.hasBeenSend && (
              <Link to={"/verificadores"}>
                <button
                  className="col-lg-auto btn btn-secondary ml-3 mr-3"
                  type="button"
                  style={buttonStyle}
                >
                  <i className="mdi mdi-close-outline mr-1"></i>
                  Limpiar filtro
                </button>
              </Link>
            )}

            {this.props.children}
          </div>
        </div>
      </form>
    );
  }
}
export default Filter;
