import React, { Component, Fragment } from 'react';
import BasicTable from '../../app/tables/BasicTable';
import Filter from '../../app/utils/Filter';
import BigCard from '../../app/utils/BigCard.jsx';
import {Link} from 'react-router-dom';

class Table extends Component {

  constructor(props){
    super(props)
    this.hasDetailedView = (props.hasDetailedView === undefined || props.hasDetailedView)
    this.friendlyPath = props.friendlyPath
    this.columns = props.columns;
    this.path = props.path
    this.isOrderAsc = true
    this.history = props.history
    this.showTableInit = props.showTableInit == null ? true : 
    this.tableUpdateOrder = this.tableUpdateOrder.bind(this)
  }
  
  table = React.createRef()

  handleHeaderClick = column => {
    this.columns.forEach( c => c.headerClasses = null )
    column.headerClasses = () => `pointer mdi mdi-arrow-${this.isOrderAsc?'up':'down'}-drop-circle-outline` 
    this.isOrderAsc = !this.isOrderAsc 
    this.tableUpdateOrder(new Map().set('sort', column.dataField).set('order', this.isOrderAsc? 'asc' : 'desc')) 
  }

  tableUpdateOrder = (data) => this.table.current.updateOrder(data)

  render () {

    let filters = []
    let tableColumns = []

    this.columns.forEach( c => {

      //Colocamos el estilo y el evento al header
      c.headerStyle = {cursor : 'pointer'}
      c.headerEvents = { onClick: (a,b) => this.handleHeaderClick(b)}

      //Colocamos el evento del clic que redirige a la vista detallada del elemento
      if( this.hasDetailedView ) {
        c.events = {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.props.history.push(`/${this.friendlyPath}/informacion-detallada/${row.id}`)
          }
        }
      }

      if(c.inputType === 'checkbox'){
        c.formatter = function(cell,row,rowIndex,formatExtraData) {
          return row.enabled ? 'Si' : 'No'
        }
      }

      //Obtenemos las columnas limpias para usarlas en el return
      let d = Object.assign({}, c);
      d.headerEvents = { }
      d.events = { }
      d.formatter = { }
      tableColumns.push(d)

      //Si la columna es también un filtro, lo agregamos al array de filtros
      if (c.isFilter) {
        filters.push({
          label: c.text,
          dataField: c.dataField,
          inputType: c.inputType,
          path: c.dropdownPath,
        })
      }
    })

    const buttonStyle = {
      height: '2.8rem',
      marginTop: '2rem'
    }

    const dataCreateLink = {
      pathname:`/${this.props.friendlyPath}/crear`, 
      state: {
        entity: tableColumns, path: this.path
      }
    }

    const createButtonText = <Fragment> <i className="fa fa-plus mr-4"></i> Crear nuevo </Fragment>

    return (
      
      <Fragment>

        { ( filters && filters.length > 0 ) && 
          <BigCard>
            <Filter filters={filters} onSend={this.tableUpdateOrder}>
            { this.props.hasCreate && 
              <Link to={dataCreateLink} className="col-lg-auto">
                <button className="btn btn-outline-primary col" style={buttonStyle}>
                  {createButtonText}
                </button>
              </Link>
            }
            { this.props.hasDownload && 
              <a 
                href={`${window.globalConfig.apiUrl}${this.path}/export`} 
                download
                className="col-lg-auto">
                <button type="button" className="btn btn-outline-primary col" style={buttonStyle}>
                  <i className="fa fa-download"></i> Exportar
                </button>
              </a>
            }
            </Filter>
          </BigCard>
        }

        { (this.props.hasDownload && filters.length < 1) && 
            <div className="mb-5 pb-4">
              <a 
                href={`${window.globalConfig.apiUrl}${this.path}/export`} 
                download>
                <button type="button" className="btn btn-primary btn-lg float-right">
                  <i className="fa fa-download"></i> Exportar
                </button>
              </a>
            </div>
        }

        { (this.props.hasCreate && filters.length < 1) && 
            <div className="mb-5 pb-4">
              <Link to={dataCreateLink}>
                <button type="button" className="btn btn-primary btn-lg float-right">
                  {createButtonText}
                </button>
              </Link>
            </div>
        }
        <BigCard>
          <BasicTable history={this.history} columns={this.columns} path={this.path} ref={this.table} hdv={this.hasDetailedView}/>
        </BigCard>

      </Fragment>
    )

  }

}

export default Table;