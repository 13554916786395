import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ModifyButton extends Component{

    render = () => 
        <Link to={{ 
            pathname:`/${ this.props.entity.friendlyPath }/modificar/${ this.props.id }`,
            defaultValue:this.props.defaultValue,
            whereToGoBack: this.props.whereToGoBack
        }}>
            <button type="button" className="btn btn-primary btn-lg ml-3 mt-4 float-right"> 
                <span className="mdi mdi-pencil mr-2"></span> Modificar
            </button>
        </Link>
}

export default ModifyButton;