import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BackButton extends Component{

	render = () => 
		<Link to={ { pathname:this.props.whereToGoBack } }>
			<button
        type="button"
        className={ `btn btn-dark btn-lg float-right ml-3 ${this.props.isCreate ? '' : 'mt-4' }` }
      > 
      <i className="fa fa-arrow-left mr-2"></i>
      Volver
			</button>
		</Link>
}
export default BackButton;