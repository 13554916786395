import React, { Component } from 'react'
import { Form } from 'react-bootstrap';

class InputNumberBasic extends Component {

  constructor(props){
    super(props)
    this.idx = props.idx;
    this.element = props.element
    this.name = this.props.property
    this.type = props.type
    this.handleChange = this.handleChange.bind(this)
    this.getValueForCreate = props.getValueForCreate
  }

  handleChange(e) {
    this.getValueForCreate(e, this.name)
  }

  render() {
      return <>
              <label  htmlFor={this.idx}>{this.element.detailedText != null ? this.element.detailedText : this.element.text}</label>
              <Form.Control 
                onChange={this.handleChange} 
                onBlur={this.handleChange} 
                className="form-control" 
                name={this.name} 
                id={this.idx===0?'0':this.idx} 
                type={this.type}
                required={this.element.required} 
                min={this.element.min ? this.element.min : 0}
                max={this.element.max ? this.element.max : 9999999999}
                defaultValue={this.element.value}
                />
          </>
  }
}

export default InputNumberBasic;
