import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import UserProfile from './UserProfile'

class Sidebar extends Component {
  constructor( props ) {
    super()
    this.user = UserProfile.getInstance()
		this.roles = this.user.userInfo.roles
    this.hasInspectorShowPermisson = this.user.hasInspectorShowPermisson()
    this.hasInspectionShowPermisson = this.user.hasInspectionShowPermisson()
  }
  state = {}

  toggleMenuState( menuState ) {
    if ( this.state[menuState] ) {
      this.setState( { [menuState] : false } )
    } else if( Object.keys( this.state ).length === 0 ) {
      this.setState( { [menuState] : true } )
    } else {
      Object.keys( this.state ).forEach( i => {
        this.setState( { [i]: false } )
      })
      this.setState( { [menuState] : true } )
    }
  }

  componentDidUpdate( prevProps ) {
    if ( this.props !== prevProps ) {
      this.onRouteChanged()
    }
  }

  onRouteChanged() {
    document.querySelector( '#sidebar' ).classList.remove( 'active' )
    Object.keys( this.state ).forEach( i => {
      this.setState( { [i]: false } )
    })

    const dropdownPaths = [
      //Propios de NSSA
      {path: 'config', state: 'configOpen'},
      {path: 'reports', state: 'reportsOpen'}
    ]

    dropdownPaths.forEach( ( obj => {
      if ( this.isPathActive( obj.path ) ) {
        this.setState( { [obj.state] : true } )
      }
    }))
  } 
  
  render () {
    return (
      <nav className="sidebar sidebar-offcanvas toggled" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <Link className="sidebar-brand brand-logo" to="/">
            <img src={ require( '../../assets/images/logo.png' ) } alt="logo" />
          </Link>
          <Link className="sidebar-brand brand-logo-mini pt-3" to="/">
            <img src={ require( '../../assets/images/logo-mini.png' ) } alt="logo" />
          </Link>
        </div>
        <ul className="nav">

          <li><ul>&nbsp;</ul></li>
          
          {/*Configuraciones - Dropdown*/}
          
          { 
            this.hasInspectorShowPermisson && 
            <li className={ this.isPathActive( '/verificadores' ) ? 'nav-item active' : 'nav-item' }>
              <Link 
                className={ this.isPathActive( '/verificadores' ) ? 'nav-link active' : 'nav-link' }  
                to="/verificadores"
              >
                <i className="mdi mdi-account-group menu-icon"></i>
                <span className="menu-title">Verificadores</span>
              </Link>
            </li>
          }

          {/*Configuraciones - Dropdown End*/}

          {/*Reportes - Dropdown*/}

          { 
          this.hasInspectionShowPermisson &&
          <li className={ this.isPathActive( '/inspecciones' ) ? 'nav-item active' : 'nav-item' }>
            <Link 
              className={ this.isPathActive( '/inspecciones' ) ? 'nav-link active' : 'nav-link' }  
              to="/inspecciones"
            >
              <i className="mdi mdi-file-clock menu-icon"></i>
              <span className="menu-title">Inspecciones</span>
            </Link>
          </li>}

          {/*Reportes - Dropdown*/}
        </ul>
      </nav>
    )
  }

  isPathActive = path => !this.props.location.pathname.startsWith( path )
  
  componentDidMount () {
    this.onRouteChanged()
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector( 'body' )
    document.body.classList.toggle( 'sidebar-icon-only' ) //OculTar sidebar al iniciar
    document.querySelectorAll( '.sidebar .nav-item' ).forEach( el => {
      
      el.addEventListener( 'mouseover', () => {
        if( body.classList.contains( 'sidebar-icon-only' ) ) {
          el.classList.add( 'hover-open' )
        }
      })
      el.addEventListener( 'mouseout', () => {
        if( body.classList.contains( 'sidebar-icon-only' ) ) {
          el.classList.remove( 'hover-open' )
        }
      })
    })
  }
}
export default withRouter( Sidebar )