import axios from "axios";
import UserProfile from "../app/shared/UserProfile";

class ApiService {
	static instance = null;
	userProfile = null;
	_axios = null;

	static getInstance(){
		if (ApiService.instance == null){
			ApiService.instance = new ApiService();
		}
		return ApiService.instance;
	}

	getUserProfile(){
		if (this.userProfile == null){
			this.userProfile = UserProfile.getInstance();
		}
		return this.userProfile;
	}

	getAxiosClient() {
		if (this._axios == null) {
			this._axios = axios.create();
			this._axios.interceptors.request.use((config) => {
				const cb = () => {
					config.headers.Authorization = `Bearer ${UserProfile.getInstance().getToken()}`;
					axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
					config.baseURL = window.globalConfig.apiUrl;
					config.timeout = window.globalConfig.apiTimeout;
					return Promise.resolve(config);
				};
				return cb();
			  });
		}
		return this._axios;
	}
}

const post = (path, data, doCallback) => {
	return ApiService.getInstance().getAxiosClient().post( path, data )
	.then( (response) => {
		if(doCallback){
			return doCallback(response);
		}
		return response;
	}).catch(function (e) {
	});
}

const get = (path, doCallback) => {
	return ApiService.getInstance().getAxiosClient().get( path );
}

const getAxios = () => {
	return ApiService.getInstance().getAxiosClient();
}

export default {
	get, post, getAxios
}