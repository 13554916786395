import React, {Component, Fragment} from 'react'
import { withRouter } from 'react-router-dom';

class Redirect extends Component{

  constructor(){
    super()
    this.state = {
      time: 6
    }
  }

  componentDidMount = () => this.handleTiming()

  handleTiming = () => {
    this.setState({ time: this.state.time - 1 } )
    if(this.state.time > 0) setTimeout(this.handleTiming, 1000)
    if(this.state.time < 1) this.props.history.push('/')
  }

  render = () => {

    const iconStyle={
      fontSize: '15em',
      color: '#128bfc'
    }

    return(
      <Fragment>
        <div className="container text-center">
          <h1> No se encontró lo que solicita </h1>
          <i onClick={this.closeWindow} className="fa fa-search mt-5 mb-5" style={iconStyle}></i>
          <h2> No se ha encontrado la entidad porque no existe </h2>
          <h3> Re-dirigiendo en {this.state.time} segundos... </h3>
          {this.state.time < 1 &&
            <a href="/"> Hace clic acá si no re-dirigió automáticamente </a>
          }
        </div>
      </Fragment>
    )
  }
}

export default withRouter(Redirect)